<template>
  <div>
    <c-auto-complete
      v-if="showAddressSearch"
      :place-holder-text="addressSearchPlaceholder"
      :result-items="autocompleteDeliveryAddresses.map(itm=> ({name:`${itm.name}, ${itm.address}`,id:itm.id} ))"
      :on-key-up="autoCompleteKeyUp"
      :on-selected="autoCompleteSelect"
      :auto-complete-progress="autocomplete.autoCompleteProgress"
      :item-text="'name'"
      :item-id="'id'"
    />
    <p class="text-md" v-if="!outputAddress">
      <strong>{{ deliveryAddress.title }}</strong>
      <template v-for="(addressLine, index) in deliveryAddress.text">
        <br :key="index" />{{ addressLine }}
      </template>
    </p>
    <p class="text-md" v-if="outputAddress">
      <strong>{{ outputAddress.title }}</strong>
      <template v-for="(addressLine, index) in outputAddress.text">
        <br :key="index" />{{ addressLine }}
      </template>
    </p>
  </div>
</template>

<script>
import { ADDRESS_TYPES } from '@/assets/js/config/server'

export default {
  name: 'DeliveryAddress',
  props: {
    locationId: {
      type: String,
      required: true
    },
    showAddressSearch: {
      type: Boolean,
      default: false
    },
    addressSearchPlaceholder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loaded: false,
      autocomplete: {
        autoCompleteProgress: false
      },
      deliveryAddresses: [],
      outputAddress: null
    }
  },
  computed: {
    location () {
      return this.$store.getters['locations/getEntityUnwrapped'](this.locationId)
    },
    locationAddresses () {
      return this.$store.getters['locationaddresses/getUnwrapped']
    },
    deliveryAddress () {
      if (!this.loaded) {
        return {
          value: '',
          title: '',
          text: []
        }
      }

      const locationDefaultAddresses = this.locationAddresses.filter(a => a.type === ADDRESS_TYPES.default)
      const locationDeliveryAddresses = this.locationAddresses.filter(a => a.type === ADDRESS_TYPES.delivery)

      const locationDeliveryAddress = locationDeliveryAddresses && locationDeliveryAddresses.length > 0 ? locationDeliveryAddresses[0] : locationDefaultAddresses[0]

      return {
        value: locationDeliveryAddress.id,
        title: this.location.name,
        text: [locationDeliveryAddress.street, `${locationDeliveryAddress.zip} ${locationDeliveryAddress.city}`].filter(i => i)
      }
    },
    autocompleteDeliveryAddresses () {
      return this.$store.getters['locations/getDecreasedEntities']
    }
  },
  methods: {
    getInitialData () {
      this.$store.dispatch('locations/getEntity', this.locationId)
      this.$store.dispatch('locationaddresses/getAll', { by: { name: 'location', id: this.locationId } })
        .then(() => {
          this.loaded = true
        })
    },
    autoCompleteKeyUp (query) {
      this.autocomplete.autoCompleteProgress = true
      this.$store.dispatch('locations/searchByQueryLimited', { data: { query } }).finally(() => {
        this.autocomplete.autoCompleteProgress = false
      })
    },
    autoCompleteSelect (id, text) {
      const address = this.autocompleteDeliveryAddresses.find(itm => itm.id === id)
      this.outputAddress = {
        value: id,
        title: address.name,
        text: address.address.split(', ').filter(i => i)
      }
      this.$store.dispatch('locations/clearSearch')
      this.$emit('select-delivery-address', id)
    }
  },
  created () {
    this.getInitialData()
  }
}
</script>
